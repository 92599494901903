<template>
  <div class="battery-slider">
    <VueSlider
      name="batteryLevelSlider"
      v-model="batteryLevel"
      :duration="0"
      :marks="[0, 100]"
      :tooltip-formatter="'{value}%'"
      :processStyle="disabled ? disabledStyle : processStyle"
      :railStyle="railStyle"
      :disabled="disabled"
      :dotSize="24"
      @change="changeSlider"
    />
  </div>
</template>

<script>
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";

const gradientFormatter = function (value) {
  const redGreen = (100 / value) * 25;
  const green = (100 / value) * 50;
  const greenBlue = (100 / value) * 75;
  const blue = (100 / value) * 100;
  return (
    "linear-gradient(90deg, #FF0000 0%, " +
    "#FFFF00 " +
    String(redGreen) +
    "%, " +
    "#00FF00 " +
    String(green) +
    "%, " +
    "#00FFFF " +
    String(greenBlue) +
    "%, " +
    "#0000FF " +
    String(blue) +
    "%)"
  );
};

export default {
  props: ["value", "disabled"],
  data() {
    return {
      railStyle: {
        height: "12px",
      },
      processStyle: {
        background:
          "linear-gradient(90deg, #FF0000 0%, #FFFF00 25%, #00FF00 50%, #00FFFF 75%, #0000FF 100%)",
      },
      disabledStyle: {
        background: "gray",
      },
    };
  },
  components: {
    VueSlider,
  },
  methods: {
    changeSlider(batteryLevel) {
      this.processStyle = {
        background: gradientFormatter(batteryLevel),
      };
    },
  },
  computed: {
    batteryLevel: {
      get() {
        return this.$props.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style>
.battery-slider {
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 20px;
}
.vue-slider-mark-step {
  width: 12px;
}
</style>
