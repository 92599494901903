<template>
  <v-slide-y-reverse-transition>
    <div>
      <LegendButton v-if="searched" class="legend-btn" />
      <RefineSettingPanel v-if="searched" class="refine-setting-panel" />
      <v-icon v-if="searched" class="start-dest-arrow">
        mdi-undo mdi-rotate-270
      </v-icon>
      <v-card class="base" :class="{ setting: searched }" id="searchSetting">
        <v-list class="card-inner" subheader>
          <div class="search-header" v-if="!searched">
            <v-icon>mdi-directions</v-icon>
            <v-list-item-action-text>
              ルート沿いの充電器を検索
            </v-list-item-action-text>
          </div>
          <Autocomplete
            inputPlaceholder="始点を検索"
            :searched="searched"
            v-model="start"
            @input="$store.commit('start', start)"
            @click="open"
          />
          <Autocomplete
            inputPlaceholder="目的地を検索"
            :searched="searched"
            v-model="destination"
            @input="$store.commit('destination', destination)"
            @click="open"
          />
          <v-card-actions v-if="!searched">
            <v-spacer></v-spacer>
            <v-btn
              :disabled="isDisabled"
              color="secondary"
              @click="searchRoute"
            >
              検索
            </v-btn>
          </v-card-actions>

          <v-list-item class="mx-2" v-if="!searched">
            <v-switch
              color="secondary"
              class="ma-0"
              v-model="tollRoad"
              :label="switchText"
            ></v-switch>
          </v-list-item>
          <v-divider v-if="!searched"></v-divider>
          <div class="search-header" v-if="!searched">
            <v-icon>electric_car</v-icon>
            <v-list-item-action-text> 車両設定 </v-list-item-action-text>
          </div>

          <v-list-item v-if="!searched">
            <CarModelSelect
              v-model="selectedEv"
              @input="$store.commit('selectedEv', selectedEv)"
            />
          </v-list-item>
          <v-subheader v-if="!searched">
            <v-list-item-action>
              <v-icon>mdi-battery-unknown</v-icon>
            </v-list-item-action>
            <v-list-item-action-text v-if="selectedEv"
              >スタート地点での電池残量
              <span class="text-subtitle-1">{{ batteryLevel }} %</span>
            </v-list-item-action-text>

            <v-list-item-action-text v-else
              >電池残量のシミュレーションのためには車両を選択してください</v-list-item-action-text
            >
          </v-subheader>
          <BatteryLevelSlider
            class="mb-8"
            v-model="batteryLevel"
            v-if="!searched"
            :disabled="!selectedEv"
            @input="$store.commit('batteryLevel', batteryLevel)"
          />
          <v-divider v-if="!searched"></v-divider>
          <div class="search-header" v-if="!searched">
            <v-icon>settings</v-icon>
            <v-list-item-action-text> アプリ設定 </v-list-item-action-text>
          </div>
          <v-list-item class="mx-2" v-if="!searched">
            <v-select
              v-model="rangeAdjustValue"
              :items="rangeAdjust"
              :disabled="!selectedEv"
              label="航続可能距離調整"
              @input="$store.commit('rangeAdjustPram', rangeAdjustValue)"
              @change="rangeAdjustChanged"
            ></v-select>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="mx-2" v-bind="attrs" v-on="on"
                  >mdi-help-circle-outline</v-icon
                >
              </template>
              <div>
                <div>
                  季節や運転特性に合わせて航続可能距離を調整し予測します
                </div>
                <div>
                  一般に、エアコンの使用や荒い運転により航続可能距離は変化し、カタログで示されている距離より短くなります
                </div>
                <div>ご自身の利用状況に合わせて調節してください</div>
              </div>
            </v-tooltip>
          </v-list-item>
        </v-list>
        <v-btn
          class="toggle-btn"
          fab
          small
          color="secondary"
          @click="searched = !searched"
        >
          <v-icon v-if="!searched">mdi-map</v-icon>
          <v-icon v-else>settings</v-icon>
        </v-btn>
      </v-card>
    </div>
  </v-slide-y-reverse-transition>
</template>

<script>
import { EventBus } from "@/event.js";
import Autocomplete from "@/components/Autocomplete.vue";
import CarModelSelect from "@/components/CarModelSelect.vue";
import BatteryLevelSlider from "@/components/BatteryLevelSlider.vue";
import LegendButton from "@/components/LegendButton.vue";
import RefineSettingPanel from "@/components/RefineSettingPanel.vue";

import axios from "axios";
import { gmapApi } from "vue2-google-maps";

export default {
  name: "Search",
  components: {
    Autocomplete,
    CarModelSelect,
    BatteryLevelSlider,
    LegendButton,
    RefineSettingPanel,
  },
  data() {
    return {
      start: null,
      destination: null,
      batteryLevel: 100,
      searched: false,
      rangeAdjustValue: 1.0,
      rangeAdjust: [
        { text: "100% (カタログ値)", value: 1.0 },
        { text: "95%", value: 0.95 },
        { text: "90%", value: 0.9 },
        { text: "85%", value: 0.85 },
        { text: "80%", value: 0.8 },
        { text: "75%", value: 0.75 },
        { text: "70%", value: 0.7 },
        { text: "65%", value: 0.65 },
        { text: "60%", value: 0.6 },
        { text: "55%", value: 0.55 },
        { text: "50%", value: 0.5 },
      ],
      tollRoad: true,
      selectedEv: null,
    };
  },
  computed: {
    switchText() {
      if (this.tollRoad) {
        return "高速道路を利用する";
      } else {
        return "高速道路を利用しない";
      }
    },
    isDisabled() {
      return this.start == null || this.destination == null;
    },
    google: gmapApi,
  },
  methods: {
    searchRoute() {
      const that = this;
      // 検索前初期化
      EventBus.$emit("loading", true);
      this.$gtag.event("search", {
        event_category: "routeSearch",
      });
      let origin;
      let destination;
      let waypoints = [];
      // ルート探索検索用の引数インスタンスの生成
      origin = new this.google.maps.LatLng(
        this.start.position.lat,
        this.start.position.lng,
        true
      );
      destination = new this.google.maps.LatLng(
        this.destination.position.lat,
        this.destination.position.lng,
        true
      );
      const directionsService = new this.google.maps.DirectionsService();
      directionsService.route(
        {
          origin,
          destination,
          waypoints,
          travelMode: "DRIVING",
          avoidHighways: !this.tollRoad,
          avoidTolls: !this.tollRoad,
        },
        function (response, status) {
          if (status === "OK") {
            // バウンディングの調整
            EventBus.$emit("bounding", response.routes[0].bounds);
            // route legsの登録
            const routeLegs = response.routes[0].legs;
            that.$store.commit("routeLegs", routeLegs);
            // 高速道路polylines,一般道polylinesの抽出
            const polylines = [];
            const highwayPolylines = [];
            routeLegs.forEach(function (leg) {
              leg.steps.forEach(function (step) {
                if (step.instructions.includes("有料")) {
                  highwayPolylines.push(step.polyline.points);
                } else {
                  polylines.push(step.polyline.points);
                }
              });
            });
            // polylinesの登録
            that.$store.commit("polylines", polylines);
            that.$store.commit("highwayPolylines", highwayPolylines);
            // 検索用polylineイベントの発火
            that.alongRouteSearch();
          } else if (status === "ZERO_RESULTS") {
            EventBus.$emit("loading", false);
            EventBus.$emit("error", "ご指定のルートを構築できませんでした");
          } else {
            EventBus.$emit("loading", false);
            EventBus.$emit(
              "error",
              "ご指定のルートを構築できませんでした(" + status + ")"
            );
          }
        }
      );
    },
    async alongRouteSearch() {
      EventBus.$emit("loading", true);
      // API call
      const method = "post";
      const url = `${process.env.VUE_APP_SEARCH_API_ENDPOINT}/search/route`;
      const start = {
        lat: this.start.position.lat,
        lon: this.start.position.lng,
      };
      const end = {
        lat: this.destination.position.lat,
        lon: this.destination.position.lng,
      };
      const data = {
        size: 1000,
        polylines: this.$store.state.polylines,
        highwayPolylines: this.$store.state.highwayPolylines,
        radius: 800,
        start,
        end,
        normalChargerFlag: this.$store.state.hasNormal,
        rapidChargerFlag: this.$store.state.hasRapid,
        alldayFlag: this.$store.state.allDay,
        dealerFlag: this.$store.state.dealer,
      };
      try {
        const response = await axios({ method, url, data });
        EventBus.$emit("loading", false);
        if (response.data.length == 0) {
          this.$store.commit("chargers", []);
          EventBus.$emit(
            "error",
            "指定の条件で近くの充電器を見つけられませんでした"
          );
        } else {
          this.$store.commit("chargers", response.data);
          EventBus.$emit("bounding");
          this.searched = true;
        }
      } catch (err) {
        console.error(err);
        EventBus.$emit("error", "エラーが発生しました");
      }
    },
    rangeAdjustChanged() {
      this.$gtag.event("select", {
        event_category: "rangeChanged",
        value: this.rangeAdjustValue,
      });
    },
    open(focusTarget) {
      if (this.searched) {
        this.searched = false;
        this.$nextTick(() => {
          focusTarget.focus();
        });
      }
    },
  },
  beforeDestroy: function () {
    this.$store.commit("start", null);
    this.$store.commit("destination", null);
    this.$store.commit("routeLegs", []);
    this.$store.commit("chargers", []);
    this.$store.commit("polylines", []);
  },
  mounted() {
    this.selectedEv = this.$store.state.selectedEv;
    EventBus.$on("refine", () => {
      this.$route.name == "Search" &&
        this.start &&
        this.destination &&
        this.alongRouteSearch();
    });
  },
  metaInfo: {
    title: "検索",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "ルートを設定した電気自動車充電計画作成にご活用ください。出発地と目的地を設定し、ルート上の電気自動車用充電器を確認！ご自身の電気自動車の電池残量のシミュレーションも行い安心してお出かけしましょう。",
      },
    ],
  },
};
</script>

<style scoped>
.base {
  transition-duration: 0.15s;
  position: absolute;
  top: 0;
  transition-property: all;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.setting {
  height: 90px;
  overflow: hidden;
}

.card-inner {
  height: 100%;
  max-width: 500px;
  overflow: auto;
  padding: 0;
  margin: auto;
}

.search-header {
  align-items: center;
  display: flex;
  height: 48px;
  font-size: 0.875rem;
  font-weight: 400;
  padding: 0 8px 0 8px;
}

.toggle-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}
@media screen and (max-width: 520px) {
  .start-dest-arrow {
    position: absolute;
    z-index: 10;
    top: 32px;
    left: 2px;
  }
}
@media screen and (min-width: 520px) {
  .start-dest-arrow {
    position: absolute;
    z-index: 10;
    top: 32px;
    left: calc((100% - 500px) / 2 + 2px);
  }
}

.legend-btn {
  position: absolute;
  z-index: 1;
  bottom: 20px;
  right: 20px;
}

.refine-setting-panel {
  position: absolute;
  z-index: 1;
  left: 0px;
  top: 90px;
}
</style>