<template>
  <div class="autocompleteComp">
    <v-list-item-subtitle class="label" v-if="!searched">{{
      inputPlaceholder
    }}</v-list-item-subtitle>
    <div class="completeBox">
      <GmapAutocomplete
        ref="autocompleteBox"
        @place_changed="setPlace"
        @search="clear"
        @click="open"
        placeholder="文字を入力"
        :selectFirstOnEnter="true"
        :options="{ componentRestrictions: { country: 'jp' } }"
        style="width: 100%; height: 28px"
      ></GmapAutocomplete>
      <v-btn v-if="!searched" icon small absolute class="clearIcon">
        <v-icon @click="clear" color="rgba(128,128,128,0.5)">close</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Object,
    inputPlaceholder: String,
    searched: Boolean,
  },
  methods: {
    setPlace(place) {
      if (place.geometry) {
        this.$gtag.event("search", {
          event_category: "searchPlace",
          event_label: place.name,
        });
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        const title = place.name;
        const address = place.formatted_address;
        const placeId = place.place_id;
        this.place = {
          position: {
            lat,
            lng,
          },
          title,
          address,
          placeId,
        };
      }
    },
    clear() {
      this.$refs.autocompleteBox.$refs.input.value = "";
      this.palce = null;
      this.$emit("input", null);
    },
    open() {
      this.$emit("click", this.$refs.autocompleteBox.$refs.input);
    },
  },
  computed: {
    place: {
      get() {
        return this.$props.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>



<style scoped>
.autocompleteComp {
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 10px;
}

.completeBox {
  position: relative;
  text-align: left;
  border-bottom-width: 1px;
  border-bottom-color: rgba(128, 128, 128, 0.5);
  border-bottom-style: solid;
}

.label {
  color: rgba(0, 0, 0, 0.6);
}

.clearIcon {
  right: 0px;
}
</style>
