<template>
  <v-select
    clearable
    v-model="selectedEvValue"
    :items="evData"
    label="車種を選択"
    class="mx-2"
    @change="evChanged"
  >
    <template v-slot:selection="{ item }">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title
            >{{ item.value.maker }} {{ item.text }}
          </v-list-item-title>
          <v-list-item-subtitle>{{ item.grade }} </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-slot:item="{ item, attrs, on }">
      <v-list-item v-on="on" v-bind="attrs">
        <v-list-item-content>
          <v-list-item-title>{{ item.text }} </v-list-item-title>
          <v-list-item-subtitle>{{ item.grade }} </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-select>
</template>

<script>
const evData = require("@/components/model/evData.json");

export default {
  props: ["value"],
  data() {
    return {
      evData,
    };
  },
  methods: {
    evChanged() {
      this.value &&
        this.$gtag.event("select", {
          event_category: "evChanged",
          event_label: `${this.value.maker} ${this.value.model}`,
        });
    },
  },
  computed: {
    selectedEvValue: {
      get() {
        return this.$props.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
